import Playground from '@appbaseio-confidential/reactivesearch-playground';

function App() {
  return (
    <div style={{ height: '100vh', margin: 0 }}>
      <Playground url="http://foo:bar@location:8000" embeded={false} />
    </div>
  );
}

export default App;
